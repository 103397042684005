import { RefObject, useCallback, useEffect, useReducer, useState } from "react";
import styles from "../../css/dist/index.module.css";
import { JSXElements, OnEventSetter, ReactTransitionStarter, SingleJSXElement, TransitionState } from "../../types";
import { User } from "../../../../lib";
import { MiniProfile } from "../util/profileparts";
import { getPFP, getRandomUsers, getUser } from "../../connections";
import { LoadingIcon } from "../util/loadingicon";
import { Animated } from "react-animated-css";
import { Link } from "../util/link";
import { GradientTextSetup } from "../util/gradienttext";
import { ArticleFooter } from "../util/footer";
import { ScrollDownIcon } from "../svg/svgs";

export const Home = ({ currUser, medium, startTransition, setTransitionState, setPath, path, mainContainerRef, theme }: { currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, medium: boolean, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, theme: string }) => {
  useEffect(() => {
    mainContainerRef.current?.scrollTo(0, 0);
  }, [mainContainerRef]);

  return (<div className={`${styles["w-full"]} ${styles["h-full"]}`}>

    <HomeWeAreSection medium={medium} currUser={currUser} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}></HomeWeAreSection>

    <HomeFeaturesSection theme={theme} medium={medium} currUser={currUser} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}></HomeFeaturesSection>

    <CallToActionSection medium={medium} currUser={currUser} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}></CallToActionSection>

    <ArticleFooter></ArticleFooter>

  </div>);
};





const CallToActionSection = ({ currUser, startTransition, setTransitionState, setPath, path, mainContainerRef, medium }: { currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, medium: boolean }) => {
  return (<div className={`${styles["h-dvh"]} ${styles["bg-primary"]} ${styles["text-primary-content"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["items-center"]} ${styles["flex-col"]} ${styles["px-12"]} ${styles["gap-12"]}`}>
    <p className={`${styles["font-extrabold"]} ${styles["text-4xl"]} ${styles["text-center"]}`}>Do not miss out on the new way to network.</p>
    <Link className={`${styles["btn"]} ${styles["text-nowrap"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["md:text-xl"]} ${styles["text-lg"]} ${styles["hover:scale-125"]} ${styles["hover:bg-accent"]} ${styles["transition-all"]} ${styles["duration-500"]}`} startTransition={startTransition} redirectPath={currUser ? "/network" : "/signup"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={currUser ? "maskTB" : "maskRL"}>{ currUser ? "Explore our network" : <>Join Badavas, forever <u>free</u></>} ⤳</Link>
  </div>);
};





const HomeFeaturesSection = ({ currUser, medium, startTransition, setTransitionState, setPath, path, mainContainerRef, theme }: { currUser: User | false | undefined, medium: boolean, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, theme: string }) => {
  // array length though equal subsection count, array represents a ref to each subsection, in order

  return (<div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["bg-gradient-to-b"]} ${styles["from-primary/10"]} ${styles["via-accent"]} ${styles["to-primary"]} ${styles["pb-[69dvh]"]}`}>

    <HomeFeaturesSubsection theme={theme} medium={medium} currUser={currUser} mockupRight={true} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}
      mockup={
        <MockupPhone imgSrc="/showcase/conversations-mobile.png" imgAlt="realtime conversations"></MockupPhone>
      }
      gradientTitle="Modern Networking"
      gradientImage={`linear-gradient(to top right, var(--r-ntv-1-f-${theme}), var(--r-ntv-1-t-${theme}))`}
      desc={
        "We fuse the elements of the past and the innovations of now to provide you with elegant, efficient networking."
      }
    ></HomeFeaturesSubsection>

    <HomeFeaturesSubsection theme={theme} medium={medium} currUser={currUser} mockupRight={true} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}
      mockup={

        medium
        ? <MockupBrowser imgSrc="/showcase/pitches-computer.png" imgAlt="video pitches" pathname="/network"></MockupBrowser>
        : <MockupPhone imgSrc="/showcase/pitches-mobile.png" imgAlt="video pitches"></MockupPhone>

      }
      gradientTitle="Video Pitches"
      gradientImage={`linear-gradient(to top right, var(--r-ntv-1-f-${theme}), var(--r-ntv-1-t-${theme}))`}
      desc={
        "No more reading pages of text to find other businesses. Just scroll; you will save hours and learn much more."
      }
    ></HomeFeaturesSubsection>

    <HomeFeaturesSubsection theme={theme} medium={medium} currUser={currUser} mockupRight={false} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}
      mockup={

        medium
        ? <MockupBrowser imgSrc="/showcase/posts-computer.png" imgAlt="posts" pathname="/posts"></MockupBrowser>
        : <MockupPhone imgSrc="/showcase/posts-mobile.png" imgAlt="posts"></MockupPhone>

      }
      gradientTitle="Posts"
      gradientImage={`linear-gradient(to top right, var(--r-ntv-2-f-${theme}), var(--r-ntv-2-t-${theme}))`}
      desc={
        "We pioneer a hybrid approach, combining the video pitches with posts to ensure efficiency but also allow you to express yourself in detail."
      }
    ></HomeFeaturesSubsection>

    <HomeFeaturesSubsection theme={theme} medium={medium} currUser={currUser} mockupRight={false} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}
      mockup={

        medium
        ? <MockupBrowser imgSrc="/showcase/recommendations-computer.png" imgAlt="ai recommendations" pathname="/recommendations"></MockupBrowser>
        : <MockupPhone imgSrc="/showcase/recommendations-mobile.png" imgAlt="ai recommendations"></MockupPhone>

      }
      gradientTitle="AI Recommendations"
      gradientImage={`linear-gradient(to top right, var(--r-ntv-2-f-${theme}), var(--r-ntv-2-t-${theme}))`}
      desc={
        <>Find your optimal partner using our powerful artificial intelligence, for <u>FREE</u>, in seconds.</>
      }
    ></HomeFeaturesSubsection>

    <HomeFeaturesSubsection theme={theme} medium={medium} currUser={currUser} mockupRight={false} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} mainContainerRef={mainContainerRef}
      mockup={
        <MockupPhone imgSrc="/showcase/search-mobile.png" imgAlt="smart search"></MockupPhone>
      }
      gradientTitle="Smart Searches"
      gradientImage={`linear-gradient(to top right, var(--r-ntv-2-f-${theme}), var(--r-ntv-2-t-${theme}))`}
      desc={
        "Normal searching is lame 💤. Try our smart searches, where you can simply find users like you are talking to an actual person."
      }
    ></HomeFeaturesSubsection>
        
  </div>);
};

const HomeFeaturesSubsection = ({ mockup, medium, gradientTitle, gradientImage, desc, mockupRight, currUser, startTransition, setTransitionState, setPath, path, theme }: { gradientImage: string, mockup: SingleJSXElement, gradientTitle: string, desc: JSXElements, medium: boolean, mockupRight: boolean, currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, theme: string }) => {

  return (<div className={`${styles["relative"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["lg:pt-72"]} ${styles["pt-12"]} ${styles["overflow-clip"]}`}>
    {
      <div className={`${styles["flex"]} ${styles["flex-col-reverse"]} ${mockupRight ? styles["lg:flex-row-reverse"] : styles["lg:flex-row"]} ${styles["lg:p-12"]} ${styles["p-8"]} ${styles["pt-24"]} ${styles["lg:gap-12"]} ${styles["gap-6"]} ${styles["lg:h-[200dvh]"]}`}>  { /** for some reason concat like: styles[`flex-row${mockupRight ? "-reverse" : ""}] didn't work... maybe implemented wrong loll */}

        <div className={`${styles["flex-1"]} ${styles["flex"]} ${styles["items-start"]} ${styles["md:items-center"]} ${styles["md:bg-transparent"]} ${styles["drop-shadow-lg"]} ${styles["md:py-12"]} ${styles["p-6"]} ${styles["rounded-3xl"]} ${styles["md:h-min"]}`}>

          { mockup }

        </div>

        <div className={`${styles["lg:sticky"]} ${styles["flex-1"]} ${styles["lg:top-[45dvh]"]} ${styles["lg:-translate-y-1/2"]} ${styles["rounded-3xl"]} ${styles["lg:mt-32"]} ${styles["lg:p-12"]} ${styles["py-6"]} ${styles["bg-base-100"]} ${styles["drop-shadow-lg"]} ${styles["lg:h-min"]} ${styles["idle-zoom"]}`}>
    
          <div className={`${styles["mx-auto"]} ${styles["w-min"]} ${styles["text-nowrap"]} ${styles["text-center"]}`}>
    
            {
              <GradientTextSetup>
              
                <h1 className={`${styles["font-bold"]} ${"md:text-3xl"} ${"lg:text-4xl"} ${styles["xl:text-5xl"]} ${styles["2xl:text-6xl"]} ${styles["text-2xl"]}`}><span className={`${styles["bg-clip-text"]} ${styles["text-transparent"]}`} style={{ backgroundImage: gradientImage }}>{ gradientTitle }</span>.</h1>
                
              </GradientTextSetup>
            }
            
    
          </div>

          <p className={`${styles["lg:mt-8"]} ${styles["mt-2"]} ${styles["xl:text-2xl"]} ${styles["lg:text-xl"]} ${styles["md:text-lg"]} ${styles["lg:px-6"]} ${styles["px-12"]} ${styles["text-center"]} ${styles["text-base"]}`}>{ desc }</p>
    
        </div>
      </div>
    }
  </div>);

};





const MockupBrowser = ({ imgSrc, imgAlt, pathname }: { imgSrc: string, imgAlt: string, pathname: string }) => {
  return (
    <div className={`${styles["mockup-browser"]} ${styles["drop-shadow-lg"]} ${styles["lg:mt-44"]} ${styles["bg-base-300"]} ${styles["idle-zoom-lg"]}`}>
      <div className={`${styles["mockup-browser-toolbar"]}`}>
        <div className={`${styles["input"]} ${styles["before:!-translate-y-[50%]"]} ${styles["after:!-translate-y-[0px]"]}`}><span>https://badavas.com{pathname}</span></div>
      </div>
      <div className={`${styles["bg-neutral"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["p-4"]}`}>
        <img src={`${process.env.PUBLIC_URL}${imgSrc}`} alt={imgAlt} className={`${styles["rounded-lg"]} ${styles["select-none"]}`}></img>
      </div>
    </div>
  );
};

const MockupPhone = ({ imgSrc, imgAlt }: { imgSrc: string, imgAlt: string }) => {
  return (
    <div className={`${styles["mockup-phone"]} ${styles["!border-none"]} ${styles["!rounded-3xl"]} ${styles["max-w-[69vw]"]} ${styles["drop-shadow-lg"]} ${styles["lg:mt-44"]} ${styles["bg-base-300"]} ${styles["idle-zoom-lg"]}`}>
      <div className={`${styles["camera"]}`}></div>
      <div className={`${styles["display"]} ${styles["!rounded-3xl"]}`}>
        <div className={`${styles["artboard"]} ${styles["artboard-demo"]} ${styles["lg:phone-1"]} ${styles["bg-black"]} ${styles["p-4"]}`}>
          <img src={`${process.env.PUBLIC_URL}${imgSrc}`} alt={imgAlt} className={`${styles["rounded-lg"]} ${styles["select-none"]}`}></img>
        </div>
      </div>
    </div>
  );
};





const HomeWeAreSection = ({ currUser, medium, startTransition, setTransitionState, setPath, path, mainContainerRef }: { currUser: User | false | undefined, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, medium: boolean }) => {
  const [weAreValues, setWeAreValues] = useState<WeAreData[] | undefined>();

  type WeAreData = [string, string, string, string, User];
  const setWeAreValuesCallback = useCallback(async (): Promise<WeAreData[]> => {
    let weAreTemplate: WeAreData[] = [
      ["manufacturers", "test", "", "", undefined as any as User],  // big title, uid, header img, pfp, user
      ["influencers", "test", "", "", undefined as any as User],    // any is valid bc purpose is just bc i don't want to write some default constructor shit and set every property of a default User obj bc not a great use of time, the end return will throw error or have User as object so we chill
      ["creators", "test", "", "", undefined as any as User],
      ["community", "test", "", "", undefined as any as User],
      ["unique", "natnuo", "", "", undefined as any as User],
    ];
    
    const needFill = [0, 1, 2, 3, 4];

    const users = await getRandomUsers(needFill.length);

    if (!users) return [];

    const failed: number[] = [];
    await Promise.all(needFill.map(async (fillRequestIX, requestIX) => {
      const uid = users[requestIX].uid;

      weAreTemplate[fillRequestIX][1] = uid;

      const [pfpBlob, user] = await Promise.all([ getPFP(users[requestIX].uid), getUser(uid) ]);

      if (!user) {
        failed.push(fillRequestIX);
        return;
      }

      const pfpURL = URL.createObjectURL(pfpBlob);

      weAreTemplate[fillRequestIX][2] = pfpURL;
      weAreTemplate[fillRequestIX][3] = pfpURL;
      weAreTemplate[fillRequestIX][4] = user;
    }));

    // remove from end to start ix to ensure accuracy bc next ixs won't be offset by prev deletions
    failed.sort((a, b) => { return +(a > b) - 0.5; }).forEach((failedIX) => {
      weAreTemplate.splice(failedIX, 1);
    });

    return weAreTemplate;
  }, []);
  useEffect(() => {
    setWeAreValuesCallback().then((newWeAreValues) => { return setWeAreValues(newWeAreValues); });
  }, [setWeAreValuesCallback]);



  const [weAreValueIX, updateWeAreValueIX] = useReducer((state: number, action: { type: "INCREMENT", upperEXBound: number }) => {
    switch (action.type) {
      case "INCREMENT":
        return (state + 1) % action.upperEXBound;
    }
  }, 0);


  const TIME_BETWEEN_WE_ARE_UPDATES_MS = 6 * 1000; const _TBWAUMS = TIME_BETWEEN_WE_ARE_UPDATES_MS;
  const ANIM_TIME_MS = 888;
  const [animVisible, setAnimVisible] = useState(true);
  useEffect(() => {

    if (!weAreValues) return;
    
    setTimeout(() => {
      setAnimVisible(false);

      setTimeout(() => {
        updateWeAreValueIX({ type: "INCREMENT", upperEXBound: weAreValues.length });
        setAnimVisible(true);
      }, ANIM_TIME_MS);  // both setTimeout calls here are okay because the expected time to update any of the dependencies is less than the set timeout time

    }, _TBWAUMS);

  }, [weAreValues, weAreValueIX, ANIM_TIME_MS, _TBWAUMS]);



  return (<div className={`${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["md:flex-row"]} ${styles["flex-col"]} ${styles["overflow-hidden"]} ${styles["bg-gradient-to-b"]} ${styles["from-accent/5"]} ${styles["to-primary/10"]} ${styles["bg-blend-darken"]}`}>

    <div className={`${styles["flex-1"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["justify-center"]} ${styles["m-6"]} ${styles["h-full"]}`}>

      <div className={`${styles["text-center"]} ${styles["font-bold"]} ${styles["text-6xl"]} `}>
        <GradientTextSetup>
          <h1 className={`${styles["text-nowrap"]}`}>
            We are
            <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={animVisible}>
              <span className={`${styles["lg:text-6xl"]} ${styles["md:text-5xl"]} ${styles["text-4xl"]}`}><span className={`${styles["text-transparent"]} ${styles["bg-clip-text"]} ${styles["bg-gradient-to-tr"]} ${styles["from-accent"]} ${styles["to-primary"]}`}>{weAreValues?.[weAreValueIX][0]}</span>.</span>
            </Animated>
          </h1>
        </GradientTextSetup>
      </div>

      <div className={`${styles["mt-16"]}`}>
        <Link className={`${styles["btn"]} ${styles["btn-accent"]} ${styles["drop-shadow-accent"]} ${styles["lg:text-2xl"]} ${styles["md:text-xl"]} ${styles["text-lg"]} ${styles["hover:scale-125"]} ${styles["hover:bg-accent"]} ${styles["transition-all"]} ${styles["duration-500"]}`} startTransition={startTransition} redirectPath={currUser ? "/network" : "/signup"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={currUser ? "maskTB" : "maskRL"}>{ currUser ? "Explore our network" : <>Join Badavas, forever <u>free</u></>} ⤳</Link>
      </div>

    </div>

    <div className={`${styles["flex-1"]} ${styles["justify-center"]} ${styles["items-center"]} ${styles["flex"]} ${styles["skew-6"]} ${styles["rotate-y-20"]} ${styles["rotate-z-20"]} ${styles["-mt-16"]} ${styles["md:mt-0"]}`}>

      <Animated animationIn="zoomIn" animationOut="zoomOut" animationInDuration={ANIM_TIME_MS} animationOutDuration={ANIM_TIME_MS} isVisible={animVisible}>

        <div className={`${animVisible ? styles["idle-zoom-lg"] : ""} ${styles["md:block"]} ${styles["hidden"]} ${styles["anim"]} ${styles["md:py-12"]} ${styles["xl:px-32"]} ${styles["lg:px-20"]} ${styles["md:px-10"]} ${styles["p-6"]} ${styles["bg-secondary"]} ${styles["rounded-lg"]}`} style={{ animationDuration: `${_TBWAUMS + ANIM_TIME_MS + 1000}ms`, animationDelay: `-500ms` }}>
          <div className={`${styles["join"]} ${styles["join-vertical"]} ${styles["lg:w-72"]} ${styles["md:w-52"]} ${styles["gap-2"]} ${animVisible ? styles["idle-zoom-xl"] : ""} ${styles["drop-shadow-lg"]} ${styles["bg-primary"]} ${styles["p-4"]}`} style={{ animationDuration: `${_TBWAUMS + ANIM_TIME_MS + 1000}ms`, animationDelay: `-500ms` }}>

            <h2 className={`${styles["font-bold"]} ${styles["text-xl"]} ${styles["text-primary-content"]} ${styles["text-center"]}`}>Featured User</h2>

            <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["items-center"]}`}>
              {/* eslint-disable-next-line */}
              <img className={`${styles["md:w-full"]} ${styles["w-2/3"]} ${styles["aspect-square"]} ${styles["rounded-lg"]}`} src={weAreValues?.[weAreValueIX][2]} alt={`${weAreValues?.[weAreValueIX][4]}'s Profile Picture`}></img>
            </div>

            <div className={`${styles["w-full"]} ${styles["flex"]} ${styles["justify-center"]} ${styles["items-center"]}`}>
              {
                weAreValues?.[weAreValueIX][4]
                ? (<>
                    <MiniProfile className={`${styles["max-w-full"]}`} noSupporterDisplay={true} innerClassName={`${styles["!p-0"]} ${styles["bg-transparent"]} ${styles["text-center"]} ${styles["text-primary-content"]}`} user={weAreValues?.[weAreValueIX][4]} startTransition={startTransition} setTransitionState={setTransitionState} setPath={setPath} path={path} noPFP ></MiniProfile>
                  </>)
                : <div className={`${styles["p-2"]}`}><LoadingIcon></LoadingIcon></div>
                // CAN ADD PFP WHEN THE HOME PAGE GETS USED FOR SELECTIVE ADVERTISING
              }
            </div>

            {
              weAreValues?.[weAreValueIX][4] && medium
              ? <span className={`${styles["text-primary-content"]} ${styles["break-words"]} ${styles["text-xs"]} ${styles["opacity-70"]} ${styles["hidden"]} ${styles["lg:block"]} ${styles["text-center"]}`}>{weAreValues[weAreValueIX][4].username} is a randomly chosen user of badavas.com. Their profile picture is shown.</span>
              : null
            }

          </div>
        </div>

      </Animated>

    </div>

    <div className={`${styles["block"]} ${styles["md:hidden"]} ${styles["animate-bounce"]} ${styles["absolute"]} ${styles["left-1/2"]} ${styles["-translate-x-1/2"]} ${styles["bottom-6"]}`}>
      <div className={`${styles["animate-pulse"]} ${styles["hover:!opacity-100"]} ${styles["hover:!ring-secondary"]} ${styles["hover:!bg-primary"]} ${styles["transition-all"]} ${styles["duration-200"]} ${styles["rounded-full"]} ${styles["bg-primary/30"]} ${styles["p-4"]} ${styles["ring-2"]} ${styles["ring-secondary/30"]} ${styles["ring-offset-2"]} ${styles["ring-offset-base-100"]}`}>
        <ScrollDownIcon className={`${styles["h-6"]} ${styles["h-6"]} ${styles["stroke-primary-content"]}`}></ScrollDownIcon>
      </div>
    </div>

  </div>);
};
