import { useCallback, useReducer, useState } from "react";
import styles from "../../css/dist/index.module.css";
import { getCurrUser, signIn } from "../../connections";
import { redirect } from "../App";
import { OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { User } from "../../../../lib";
import { Link } from "../util/link";
import { SingleFullInputStep } from "../util/inputtemplates";
import { accentButtonClassName, minorButtonClassName } from "../util/classnames";

export const SignIn = ({ startTransition, setTransitionState, setPath, path, setCurrUser }: { startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, path: string, setPath: OnEventSetter<string>, setCurrUser: OnEventSetter<User> }) => {
  const [attemptingSignIn, setAttemptingSignIn] = useState(false);

  const [userIDInput, setUserIDInput] = useReducer((_: string, event: React.ChangeEvent<HTMLInputElement>) => {
    return event.target.value;
  }, "");
  const [passwordInput, setPasswordInput] = useReducer((_: string, event: React.ChangeEvent<HTMLInputElement>) => {
    return event.target.value;
  }, "");

  const [errorText, setErrorText] = useState<string | undefined>();

  const handleSignInAttempt = useCallback(async () => {
    if (attemptingSignIn) return;

    setAttemptingSignIn(true);

    if (!userIDInput || !passwordInput) {
      setErrorText("We should fill out all inputs...");
      setAttemptingSignIn(false);
      return;
    }

    const isSuccessfulLogin = await signIn(userIDInput.trim(), passwordInput);

    if (!isSuccessfulLogin) {
      setErrorText("Invalid credentials");
      setAttemptingSignIn(false);
      return;
    }

    const newCurrUser = await getCurrUser();
    if (!newCurrUser) {
      setErrorText("Sign in failed, please try again.");
      setAttemptingSignIn(false);
      return;
    }

    setCurrUser(newCurrUser);

    redirect(startTransition, path === "/signin" ? "/upload" : path, "maskTB", "maskTB", setTransitionState, setPath);
  }, [attemptingSignIn, userIDInput, passwordInput, startTransition, setTransitionState, setPath, setCurrUser, path]);

  return (<SingleFullInputStep errorText={errorText} onErrorClose={() => { setErrorText(undefined); }} label={"Sign In"}>
    <input type="text" placeholder="User ID" className={`${styles.input}`} onChange={setUserIDInput}></input>
    <input type="password" placeholder="Password" className={`${styles.input}`} onChange={setPasswordInput}></input>
    <button className={`${accentButtonClassName}`} onClick={handleSignInAttempt}>Sign In</button>
    <Link startTransition={startTransition} redirectPath={"/res-pw"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${minorButtonClassName}`}>Reset Password</Link>
    <Link startTransition={startTransition} redirectPath={"/signup"} setTransitionState={setTransitionState} setPath={setPath} path={path} transition={"maskRL"} className={`${minorButtonClassName}`}>Sign Up</Link>
  </SingleFullInputStep>);
};
