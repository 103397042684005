import { checkUIDValidity } from "./connections";

const checkIllegal = async (s: string, pt: string, allowExtra: boolean) => {
  const illegalSequences = ["{", "}", "Business A", "Business B", "Businesses A and B", "Businesses B and A", " 500 "];

  let foundIllegal: string[] = [];
  await Promise.all(illegalSequences.map((vv) => {
    const rss = !s.includes(vv);
    if (!rss) foundIllegal.push(vv);
    return rss;
  }));
  if (foundIllegal.length > 0) return `${pt} may not contain these sequences: ${foundIllegal.join(", ")}`;

  const matches = s.match(/[A-Za-z0-9_.\-~]+/g);
  if (matches&&!allowExtra&&matches[0]!==s) return "Illegal characters found";

  return "";
}

export const VALIDITY_CHECKS = {
  "uid": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter a user ID.";
    }
    if (value.length > 30) {
      return `User ID: Over length limit with ${value.length}/30 characters`;
    }
    for (let i = 0; i < value.length; i++) {
      const charCode = value.charCodeAt(i);
      if (charCode > 64 && charCode < 91) {
        return "User ID letters must be lowercase.";
      } else if (
        charCode !== 45 &&
        charCode !== 95 &&
        charCode !== 151 &&
        !(charCode > 47 && charCode < 58) &&
        !(charCode > 96 && charCode < 123)
      ) {
        return "User ID characters must be lowercase alphanumeric, _, or - (no en/em dashes).";
      }
    }
    if (!(await checkUIDValidity(value.trim()))) {
      return "This user ID has been taken.";
    }
    return checkIllegal(value, "User ID", true);
  },
  "type": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please select a user class.";
    }
    if (!(new Set(["Individual", "Company"]).has(value))) {
      return `"${value}" is not a valid user class.`;
    }
    return "";
  },
  "username": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter a username.";
    }
    if (value.length > 65) {
      return `Username: Over length limit with ${value.length}/65 characters`;
    }
    return checkIllegal(value, "Username", true);
  },
  "description": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter a self-description.";
    }
    if (value.length < 100) {
      return `Write more about your yourself! Minimum 100 characters (currently ${value.length}) for your self-description`;
    }
    if (value.length > 256) {
      return `Self-Description: Over length limit with ${value.length}/256 characters`;
    }
    return checkIllegal(value, "Wants", true);
  },
  "wants": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please specify what you want from a partner.";
    }
    if (value.length < 100) {
      return `Write more about your wants! Minimum 100 characters (currently ${value.length})`;
    }
    if (value.length > 256) {
      return `Ideal Partner Description: Over length limit with ${value.length}/256 characters`;
    }
    return checkIllegal(value, "Wants", true);
  },
  "email": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter an email.";
    }
    if (value.length > 255) {
      return `Email: Over length limit with ${value.length}/255 characters`;
    }
    const match = value.match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
    );
    if (!match || match[0] !== value) return "Please input a valid email.";
    return "";
  },
  "supporter_banner_url": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter a url for your Super Supporter Banner.";
    }
    if (value.length > 256) {
      return `Super Supporter Banner URL: Over length limit with ${value.length}/256 characters`
    }

    const validExtensions = new Set(["png", "jpeg", "jpg", "gif"]);

    const valSplit = value.split(".");
    if (!valSplit.length || !validExtensions.has(valSplit[valSplit.length - 1])) {
      return `Super Supporter Banner URL must end in ".png", ".jpeg", ".jpg", or ".gif"`;
    }

    return checkIllegal(value, "A Supporter's Banner URL", true);
  },
  "password": async (value: string): Promise<string> => {
    if (!value.length) {
      return "Please enter a password.";
    }
    if (value.length > 256) {
      return `Password: Over length limit with ${value.length}/256 characters`
    }
    if (value.includes("\\")) return "A password cannot contain backslashes.";
    return checkIllegal(value, "Password", true);
  },
  "avail": async (value: string): Promise<string> => {
    return "";
  },
};

export const FILE_VALIDITY_CHECKS = {
  "pfp": async (file: File | undefined, supportedtypes: string): Promise<string> => {
    if (!file) {
      return "Please choose a profile picture.";
    }

    const MAX_PFP_STORAGE_SIZE_KB = 100; const _MPSSKB = MAX_PFP_STORAGE_SIZE_KB;
    if (file.size > _MPSSKB * 1024) {  // * 1024 to reduce rounding errors (off-no chance, but still, small change and worth protection [although this typing spends more time, but helps to clarify the reason this code is better than a initially seemingly equivalent alternative])
      return `Expected file size less than ${_MPSSKB} KB for profile picture, recieved ${(file.size / 1024).toFixed(1)} KB`;
    }

    const validTypes = new Set(supportedtypes.replaceAll(" ", "").split(","));
    if (!validTypes.has(file.type)) {
      return `Expected file of type: ${supportedtypes.replaceAll("image/", "").replaceAll(" ", "").split(",")} for profile picture, got file of type: ${file.type}`;
    }

    return "";
  },
}
