import { RefObject, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { JSXElements, OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import { VerticalStepSequence } from "../util/stepsequence";
import styles from "../../css/dist/index.module.css";
import { createUser, getCurrUser, UserBlueprint } from "../../connections";
import { ErrorNotif } from "../util/errornotif";
import { VALIDITY_CHECKS } from "../../validity-checks";
import { redirect } from "../App";
import { User } from "../../../../lib";
import { excitingAccentButtonClassName } from "../util/classnames";

export const SignUp = ({ updateTimeLeftForResendText, startTransition, setTransitionState, setPath, path, mainContainerRef, setCurrUser }: { updateTimeLeftForResendText: (action: "REDUCE" | "RESET") => void, startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>, setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>, setCurrUser: (newCurrUser: User) => void }) => {
  const [stepCompletionStates, updateStepCompletionStates] = useReducer((state: boolean[], action: { type: "INITIALIZE", stepCount: number } | { type: "UPDATE", stepIX: number, newValue: boolean } | { type: "ACTIVATE UNTIL", stepUntilIX: number }) => {
    switch (action.type) {
      case "INITIALIZE":
        const arr: boolean[] = new Array(action.stepCount).fill(false);
        arr[0] = true;
        return arr;
      case "UPDATE":
        state[action.stepIX] = action.newValue;
        return state;
      case "ACTIVATE UNTIL":
        return state.map((_, ix) => { return ix <= action.stepUntilIX; });
    }
  }, []);

  useEffect(() => {
    updateStepCompletionStates({ type: "INITIALIZE", stepCount: 3 });
    mainContainerRef.current?.scrollTo(0, 0);
  }, [mainContainerRef]);





  const [errorText, setErrorText] = useState<string | undefined>();

  const [userType, setUserType] = useState<undefined | "Individual" | "Company">();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const uidInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInput1Ref = useRef<HTMLInputElement>(null);
  const passwordInput2Ref = useRef<HTMLInputElement>(null);
  const wantsTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const descriptionTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const validityCheck = useCallback(async (): Promise<[string, UserBlueprint | undefined]> => {
    if (!usernameInputRef.current || !usernameInputRef.current.value) return ["Please enter a username.", undefined];
    if (!uidInputRef.current || !uidInputRef.current.value) return ["Please enter a user ID.", undefined];
    if (!emailInputRef.current || !emailInputRef.current.value) return ["Please enter a email.", undefined];
    if ((!passwordInput1Ref.current || !passwordInput1Ref.current.value) && (!passwordInput2Ref.current || !passwordInput2Ref.current.value)) return ["Please enter a password.", undefined];
    if (!passwordInput1Ref.current || !passwordInput1Ref.current.value || !passwordInput2Ref.current || !passwordInput2Ref.current.value) return ["Please type your password in both related fields.", undefined];
    if (!wantsTextAreaRef.current || !wantsTextAreaRef.current.value) return ["Please enter your wants.", undefined];
    if (!descriptionTextAreaRef.current || !descriptionTextAreaRef.current.value) return ["Please enter your description.", undefined];

    if (passwordInput1Ref.current.value !== passwordInput2Ref.current.value) return ["Your password inputs are not the same.", undefined];

    const userTypeCheckError = await VALIDITY_CHECKS["type"](userType ?? "");
    if (userTypeCheckError) return [userTypeCheckError, undefined];
    
    
    const userBlueprint: UserBlueprint = {
      uid: uidInputRef.current.value.trim() as string,
      username: usernameInputRef.current.value.trim() as string,
      email: emailInputRef.current.value.trim() as string,
      password: passwordInput1Ref.current.value as string,
      wants: wantsTextAreaRef.current.value.trim() as string,
      description: descriptionTextAreaRef.current.value.trim() as string,
      type: +(userType === "Individual"),
    };

    // userProperty === component of property
    // shld have named those properties properties, not components :/// all g both make sense, fuck standardized terminology
    for (let [userProperty, value] of Object.entries(userBlueprint)) {
      if (userProperty === "type") continue;  // already checked; checked earlier bc of diff format

      const errorResult = await VALIDITY_CHECKS[userProperty as keyof UserBlueprint](value);
      if (errorResult) return [errorResult, undefined];
    }


    return ["", userBlueprint];
  }, [userType]);





  const [attemptingSignUp, setAttemptingSignUp] = useState(false);

  const failAttempt = useCallback(() => {
    setAttemptingSignUp(false);
    return false;
  }, []);

  const attemptSignUp = useCallback(async () => {
    if (attemptingSignUp) {
      failAttempt();
      return;
    }

    setAttemptingSignUp(true);

    const [errorResult, userBlueprint] = await validityCheck();
    if (errorResult) {
      failAttempt();
      setErrorText(errorResult);
      return;
    }
    if (!userBlueprint) {
      failAttempt();
      setErrorText("Something unexpected happened... please try signing up again.");
      return;
    }
    
    createUser(userBlueprint).then((cuSuccess) => {
      if (!cuSuccess) {
        failAttempt();

        if (cuSuccess === undefined) {
          setErrorText("Something unexpected happened... please try signing up again.");
        }
        setErrorText("Invalid email address");

        return;
      }
      
      updateTimeLeftForResendText("RESET");
      getCurrUser().then((user) => {
        if (!user) {
          redirect(startTransition, "/signin", "maskRL", "maskRL", setTransitionState, setPath);
          return;
        }
        setCurrUser(user);
        redirect(startTransition, "/upload", "maskTB", "maskTB", setTransitionState, setPath);
      });
    });

    return;
  }, [attemptingSignUp, failAttempt, validityCheck, setCurrUser, setPath, setTransitionState, startTransition, updateTimeLeftForResendText]);





  return (<div className={`${styles["w-full"]} ${styles["flex-col"]} ${styles["h-full"]} ${styles["flex"]} ${styles["pt-4"]} ${styles["items-center"]} ${styles["max-w-5xl"]} ${styles["mx-auto"]} ${styles["p-6"]}`}>
    <div className={`${styles["text-center"]} ${styles["py-2"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]}`}>
      <h1 className={`${styles["text-6xl"]} ${styles["font-extrabold"]} ${styles["bg-clip-text"]} ${styles["p-10"]} ${styles["-m-10"]} ${styles["bg-gradient-to-br"]} ${styles["text-transparent"]} ${styles["from-primary"]} ${styles["via-primary"]} ${styles["to-secondary"]}`}>Sign Up</h1>
      <span className={`${styles["text-xl"]} ${styles["opacity-90"]} ${styles["bg-clip-text"]} ${styles["p-10"]} ${styles["-m-10"]} ${styles["bg-gradient-to-br"]} ${styles["text-transparent"]} ${styles["from-primary"]} ${styles["via-primary"]} ${styles["to-secondary"]}`}>✈️ 3 <i>easy</i> steps.</span>
    </div>

    <div>
      <VerticalStepSequence stepCompletionStates={stepCompletionStates} className={`${styles["overflow-x-hidden"]}`}>


        <SignUpStep title="📓 Login Information." onClick={() => {
          updateStepCompletionStates({ type: "ACTIVATE UNTIL", stepUntilIX: 0 });
        }}>
          <input ref={usernameInputRef} type="text" className={`${styles["input"]} ${styles["text-sm"]} ${styles["input-bordered"]} ${styles["input-ghost"]}`} id="username-input" placeholder="Username"></input>
          <input ref={uidInputRef} type="text" className={`${styles["input"]} ${styles["text-sm"]} ${styles["input-bordered"]} ${styles["input-ghost"]}`} autoComplete="username" id="uid-input" placeholder="User ID"></input>
          <input ref={emailInputRef} type="email" className={`${styles["input"]} ${styles["text-sm"]} ${styles["input-bordered"]} ${styles["input-ghost"]}`} id="email-input" placeholder="Email (Will be Public)"></input>
          <input ref={passwordInput1Ref} type="password" className={`${styles["input"]} ${styles["text-sm"]} ${styles["input-bordered"]} ${styles["input-ghost"]}`} id="password-input-1" placeholder="Password"></input>
          <input ref={passwordInput2Ref} type="password" className={`${styles["input"]} ${styles["text-sm"]} ${styles["input-bordered"]} ${styles["input-ghost"]}`} id="password-input-2" placeholder="Confirm Password"></input>
        </SignUpStep>


        <SignUpStep title="🤝 Your Partner." onClick={() => {
          updateStepCompletionStates({ type: "ACTIVATE UNTIL", stepUntilIX: 1 });
        }}>
          <p className={`${styles["text-sm"]}`}>Describe your ideal partner, and Badavas will find your pick. Try to the specify your ideal partner's location (town, province, state), size, product, industry, and specialty (100-256 characters).</p>
          <textarea ref={wantsTextAreaRef} className={`${styles["textarea"]} ${styles["textarea-ghost"]} ${styles["text-sm"]} ${styles["textarea-bordered"]} ${styles["h-52"]}`} id="description-textarea" placeholder="Your Wants (e.g. A fresh produce provider based in or near Westchester County, New York. Preferred products are fresh tomatos, fresh spinach, and fresh mushrooms)."></textarea>
        </SignUpStep>


        <SignUpStep title="🎖️ You." onClick={() => {
          updateStepCompletionStates({ type: "ACTIVATE UNTIL", stepUntilIX: 2 });
        }}>
          <p className={`${styles["text-sm"]}`}>Describe your brand. For best results, include your location (town, province, state), size, product, industry, and specialties (100-256 characters).</p>
          <textarea ref={descriptionTextAreaRef} className={`${styles["textarea"]} ${styles["lg:h-20"]} ${styles["md:h-28"]} ${styles["sm:h-44"]} ${styles["h-64"]} ${styles["textarea-ghost"]} ${styles["textarea-bordered"]} ${styles["text-sm"]}`} id="description-textarea" placeholder="Your Description (e.g. Family-run boba shop located in New York City, New York. Selling online within the United States and locally within New York City)."></textarea>
          <p className={`${styles["text-sm"]}`}>You are:</p>
          <div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]}`}>
            <button className={`${styles["btn"]} ${styles["btn-outline"]} ${styles["border-base-content"]} ${styles["w-full"]} ${userType === "Individual" ? `${styles["border-opacity-100"]}` : `${styles["border-opacity-20"]}`}`} onClick={() => { setUserType("Individual"); }}>An Individual</button>
            <button className={`${styles["btn"]} ${styles["btn-outline"]} ${styles["border-base-content"]} ${styles["w-full"]} ${userType === "Company" ? `${styles["border-opacity-100"]}` : `${styles["border-opacity-20"]}`}`} onClick={() => { setUserType("Company"); }}>A Company</button>
          </div>
          <button className={`${excitingAccentButtonClassName}`} onClick={attemptSignUp}>Sign Up</button>
        </SignUpStep>


      </VerticalStepSequence>
    </div>

    <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
  </div>);
};

const SignUpStep = ({ title, children, onClick }: { title: string, children: JSXElements, onClick?: () => void }) => {
  return (<div className={`${styles["flex-col"]} ${styles["flex"]} ${styles["text-xl"]} ${styles["w-full"]} ${styles["text-center"]} ${styles["items-center"]} ${styles["-ml-2"]} ${styles["p-6"]} ${styles["box-border"]} ${styles["gap-2"]} ${styles["bg-neutral"]} ${styles["text-neutral-content"]} ${styles["rounded-lg"]} ${styles["drop-shadow-md"]}`} onClick={onClick}>
    <h2 className={`${styles["font-bold"]} ${styles["pb-2"]}`}>{title}</h2>
    <div className={`${styles["gap-4"]} ${styles["flex-col"]} ${styles["flex"]} ${styles["w-full"]}`}>
      { children }
    </div>
  </div>);
};
